<template>
  <div class="view-sealManage" @click="handleActive = null">
    <div class="content" v-if="!treeShow">
      <ul class="list">
        <li class="item" v-for="(item, index) in sealList" :key="index">
          <div class="item-top">
            <div class="name">{{ item.sealName }}</div>
            <div class="holder">印章持有人</div>
            <div
              class="more"
              @click.stop="handleActive = handleActive == index ? null : index"
            >
              <img src="@/assets/imgs/sealManage/more.png" alt="" />
            </div>
          </div>
          <div class="item-content">
            <div class="sealImg">
              <img :src="item.sealUrl" alt="" />
            </div>
            <ul class="sealOwnerList">
              <li
                class="owner"
                v-for="(owner, i) in item.sealOwnerList"
                :key="i"
              >
                {{ owner.sealOwnerName }}({{ owner.telephone }})
              </li>
            </ul>
          </div>
          <ul
            class="item-handle"
            v-show="handleActive == index"
            @click.stop="1"
          >
            <li class="add" @click="addholder(item)">
              <img src="@/assets/imgs/sealManage/add.png" alt="" />
              添加持有人
            </li>
            <li @click="goAddSeal(item.sealId)">编辑</li>
            <li
              :class="{ disabled: item.status }"
              @click="item.status ? '' : handleStatus(item.sealId, 1)"
            >
              启用
            </li>
            <li
              :class="{ disabled: !item.status }"
              @click="!item.status ? '' : handleStatus(item.sealId, 0)"
            >
              停用
            </li>
            <li @click="handleDelete(item.sealId)">删除</li>
          </ul>
        </li>
      </ul>
      <div class="view-button">
        <van-button type="info" @click="goAddSeal()">
          制作印章
        </van-button>
      </div>
      <van-dialog
        className="deleteDialog"
        v-model="showDelete"
        title="删除印章"
        show-cancel-button
        cancelButtonText="确认移除"
        confirmButtonText="暂不移除"
        @cancel="updateSealStatus(deleteId, 2)"
      >
        <div>
          该印章涉及以下未完成文件，若移删除该印章，以下未完成文件将失效，请谨慎操作
        </div>
        <div>
          <span
            v-for="(item, index) in sealMsg.businessList"
            :key="index"
            style="color: #1676ff"
            >《{{ item }}》</span
          >
        </div>
        <div>
          该印章涉及以下未完成文件，若移删除该印章，以下未完成文件将失效，请谨慎操作
        </div>
        <div>
          <span
            v-for="(item, index) in sealMsg.signFileList"
            :key="index + '0'"
            style="color: #1676ff"
            >{{ item }}</span
          >
        </div>
      </van-dialog>
    </div>
    <companyFriend
      :show="treeShow"
      :type="0"
      @roleCallback="addSealRoleAuth"
      @onConfirmClose="treeShow = false"
      @onClose="treeShow = false"
    ></companyFriend>
  </div>
</template>

<script>
  import companyFriend from '@/components/selectPerson/companyFriend.vue'
  import sealApi from '@/api/seal'

  export default {
    components: {
      companyFriend,
    },
    data() {
      return {
        sealList: [],
        handleActive: null,
        showDelete: false,
        sealMsg: {
          businessList: [],
          signFileList: [],
        },
        deleteId: null,
        treeShow: false,
        changeSealId: null,
      }
    },
    created() {
      this.getSealList()
    },
    methods: {
      addSealRoleAuth(userList) {
        const userIdList = userList.map(e => e.uid)
        sealApi
          .addSealRoleAuth({
            sealId: this.changeSealId,
            userIdList,
          })
          .then(res => {
            this.$toast('添加持有人成功')
            this.getSealList()
          })
      },
      addholder(item) {
        this.changeSealId = item.sealId
        this.treeShow = true
      },
      handleDelete(sealId) {
        if (this.sealList.length === 1) {
          this.$dialog
            .confirm({
              title: '删除印章',
              message: '请至少为您的企业保留一个印章',
              showCancelButton: false,
              confirmButtonText: '知道了',
            })
            .then(() => {
              // on confirm
            })
            .catch(() => {
              // on cancel
            })
          return
        }
        this.$dialog
          .confirm({
            title: '删除印章',
            message: '删除印章后，持有成员将不能使用本印章',
          })
          .then(() => {
            // on confirm
            this.getSealRelation(sealId)
          })
          .catch(() => {
            // on cancel
          })
      },
      getSealRelation(sealId) {
        sealApi
          .getSealRelation({
            sealId,
          })
          .then(res => {
            this.sealMsg = res
            if (
              this.sealMsg.businessList.length !== 0 ||
              this.sealMsg.signFileList.length !== 0
            ) {
              this.showDelete = true
              this.deleteId = sealId
              return
            }
            this.updateSealStatus(sealId, 2)
          })
      },
      handleStatus(sealId, opType) {
        let title = ''
        let message = ''
        switch (opType) {
          case 0:
            title = '停用'
            message = '确定停用此印章？'
            break
          case 1:
            title = '启用'
            message = '确定启用此印章？'
            break
          default:
            break
        }
        this.$dialog
          .confirm({
            title,
            message,
          })
          .then(() => {
            // on confirm
            this.updateSealStatus(sealId, opType)
          })
          .catch(() => {
            // on cancel
          })
      },
      updateSealStatus(sealId, opType) {
        // 0-停用 1-启用 2-删除 3-删除所有人
        const obj = {
          sealId,
          opType,
        }
        sealApi.updateSealStatus(obj).then(res => {
          this.handleActive = null
          if (opType === 1) {
            this.$toast('印章已启用')
          } else if (opType === 0) {
            this.$toast('印章已停用')
          } else if (opType === 2) {
            this.$toast('移除成功')
          }
          this.getSealList()
        })
      },
      getSealList() {
        sealApi.getSealList({}).then(res => {
          this.sealList = res
        })
      },
      goAddSeal(id) {
        if (this.sealList.length >= 8 && !id) {
          this.$dialog
            .confirm({
              title: '制作印章',
              message: '企业最多只能持有8个印章，不能制作',
            })
            .then(() => {
              // on confirm
            })
            .catch(() => {
              // on cancel
            })
          return
        }
        this.$router.push({
          path: '/addSeal',
          query: {
            id: id || null,
          },
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .view-sealManage {
    background: #f4f5f6;
    .content {
      padding: 16px;
      padding-bottom: 160px;
    }
    .list {
      .item {
        position: relative;
        height: 148px;
        background: #ffffff;
        border: 1px solid rgba(22, 118, 255, 0.08);
        border-radius: 4px;
        margin-bottom: 16px;
        .item-top {
          height: 40px;
          border-bottom: 1px solid #f0f5ff;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #434751;
          line-height: 20px;
          padding: 0 13px 0 16px;
          .name {
            width: 136px;
          }
          .holder {
            flex: 1;
          }
          .more {
            padding: 3px;
            img {
              width: 3px;
            }
          }
        }
        .item-content {
          padding: 12px 16px 16px;
          display: flex;
          .sealImg {
            height: 80px;
            width: 136px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              width: 80px;
            }
          }
          .sealOwnerList {
            flex: 1;
            box-sizing: border-box;
            padding-top: 3px;
            height: 80px;
            overflow-y: auto;
            .owner {
              font-size: 12px;
              font-family: PingFangSC, PingFangSC-Regular;
              font-weight: 400;
              text-align: center;
              color: #434751;
              line-height: 17px;
              margin-bottom: 14px;
            }
          }
        }
        .item-handle {
          z-index: 1;
          position: absolute;
          top: 40px;
          right: 7px;
          width: 115px;
          background: #646464;
          box-shadow: 0px 0px 8px 0px rgba(215, 215, 215, 0.5);
          border-radius: 4px;
          font-size: 14px;
          font-family: PingFangSC, PingFangSC-Regular;
          font-weight: 400;
          text-align: center;
          color: #ffffff;
          line-height: 40px;
          &:after {
            position: absolute;
            right: 5px;
            top: -5px;
            content: '';
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #646464;
          }
          li {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
          }
          .add {
            border-bottom: 1px solid rgba(255, 255, 255, 0.45);
            img {
              width: 12px;
              margin-right: 5px;
            }
          }
          .disabled {
            color: rgba(255, 255, 255, 0.4);
          }
        }
      }
    }
    .deleteDialog {
      /deep/ .van-dialog__header {
        font-size: 20px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: center;
        color: #111a34;
        line-height: 20px;
        padding-top: 24px;
      }
      /deep/ .van-dialog__content {
        width: 100%;
        word-break: break-all;
        max-height: 400px;
        overflow-y: auto;
        padding: 24px;
        font-size: 16px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #677283;
        line-height: 24px;
      }
    }
  }
</style>
